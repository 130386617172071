


import SECTION_FISHINGGAMEBANNERSECTION from
'../dynamic_section/dynamic_2/template_01';





import SECTION_FISHINGGAMECONTAINERSECTION from
'../dynamic_section/dynamic_1/template_01';





import SECTION_FOOTER_BANNER from
'../dynamic_section/dynamic_2/template_01';



import "./style.css";


const FishingPageDesktop = ()=>{
  
return (
  <div id="fishingPageDesktop" class="page ">
  
  

  
  < SECTION_FISHINGGAMEBANNERSECTION desktopToggle={ true }
    mobileToggle={ false } label={ `fishing_game_banner` } id={ `fishing_banner` } supportLanguage={
    true } titleToggle={ false } titleIconToggle={
    true } linkTo={ `` } addContainer={ false } numberOfImage={ 1 } titlePosition={ `start` }/>
  
  
  
  

  
  < SECTION_FISHINGGAMECONTAINERSECTION desktopToggle={ true }
    mobileToggle={ false } label={ `Section_fishing game container` } id={ `fishingGameContainer` }
    imageSize={`1x1`} contentPosition={`none`}
    actionPosition={``} rowDesktop={ 6 }
    rowMobile={ 3 } numberOfRow={ -1 }
    dataType={`game provider`} noPadding={ false } filter={`FH`}
    titleToggle={ false } titleIconToggle={ true }
    cardPosition={`center`} pagination={ true }
    autoplay={ true } navigation={ true }
    dynamicQuery={`` } filterTabToggle={ false }
    folderImage={``} noLink={ false }/>
  
  
  
  

  
  < SECTION_FOOTER_BANNER desktopToggle={ true }
    mobileToggle={ false } label={ `Dynamic Section2` } id={ `footer_banner` } supportLanguage={
    true } titleToggle={ false } titleIconToggle={
    true } linkTo={ `` } addContainer={ false } numberOfImage={ 1 } titlePosition={ `start` }/>
  
  
  

  
</div>
)
}

export default FishingPageDesktop;