



import WalletSection from '../sections/wallet/default';






import SECTION_FOOTER_BANNER from
'../dynamic_section/dynamic_2/template_01';



import "./style.css";


const WalletPage = ()=>{
  
return (
  <div id="walletPage" class="page ">
  
  

  
  <WalletSection />
  
  
  
  

  
  < SECTION_FOOTER_BANNER desktopToggle={ true }
    mobileToggle={ false } label={ `Dynamic Section2` } id={ `footer_banner` } supportLanguage={
    true } titleToggle={ false } titleIconToggle={
    true } linkTo={ `` } addContainer={ false } numberOfImage={ 1 } titlePosition={ `start` }/>
  
  
  

  
</div>
)
}

export default WalletPage;